import { getHours, getMinutes } from 'date-fns';
import { LooseObject } from '~/types/generic';

type WidgetType = 'classic' | 'chat' | 'talkdesk';

export const SUPPORT_WIDGET_TYPE: WidgetType = 'talkdesk';

export const useSupport = () => {
  const getTalkdeskShadowRoot = (): ShadowRoot | null => {
    return document.querySelector('#tdWebchat')?.shadowRoot || null;
  };

  const getTalkdeskChatCta = (): HTMLButtonElement | null => {
    const shadowRoot = getTalkdeskShadowRoot();
    if (!shadowRoot) {
      return null;
    }
    const button = shadowRoot.querySelector('.digital-webchat-wrapper button');
    return button ? (button as HTMLButtonElement) : null;
  };

  const openTalkdesk = () => {
    if (!window.webchat) {
      return;
    }
    getTalkdeskChatCta()?.click();
  };

  const closeTalkdesk = () => {
    if (!window.webchat) {
      return;
    }
    console.log('close talkdesk');
    // const shadowRoot = getTalkdeskShadowRoot();
    // if (shadowRoot) {
    //   console.log(shadowRoot.querySelector('#botonic-webchat data-qa[minimize-button]'));
    // }
  };

  const openChat = () => {
    if (window.zE) {
      window.zE('messenger', 'open');
    }
  };

  const closeChat = () => {
    if (window.zE) {
      window.zE('messenger', 'close');
    }
  };

  const hideClassicWidget = () => {
    if (window.zE) {
      window.zE('webWidget', 'close');
    }
  };

  const showClassicWidget = () => {
    if (window.zE) {
      window.zE('webWidget', 'open');
    }
  };

  const openSupportChat = (type: WidgetType = SUPPORT_WIDGET_TYPE) => {
    if (type === 'chat') {
      return openChat();
    }
    if (type === 'talkdesk') {
      return openTalkdesk();
    }
    return showClassicWidget();
  };

  const closeSupportChat = (type: WidgetType = SUPPORT_WIDGET_TYPE) => {
    if (type === 'chat') {
      return closeChat();
    }
    if (type === 'talkdesk') {
      return closeTalkdesk();
    }
    return hideClassicWidget();
  };

  const isWithinBusinessHours = () => {
    const workDays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const startHour = 10;
    const endHour = 18;

    const isInsideHours = (hour: number) => hour >= startHour && hour < endHour;
    const isWorkingDay = (weekday: any) => workDays.includes(weekday);

    const parseDateSegments = (date = new Date()) =>
      new Intl.DateTimeFormat('en-US', {
        timeZone: 'Pacific/Auckland',
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        weekday: 'short',
        hour: 'numeric',
        hour12: false,
        minute: 'numeric',
      })
        .formatToParts(date)
        .reduce((acc: LooseObject, part) => {
          const { type, value } = part;
          if (type === 'weekday') {
            acc.weekday = value;
          } else if (type !== 'literal') {
            acc[type] = parseInt(value, 10);
          }
          return acc;
        }, {});

    const { weekday, hour } = parseDateSegments(new Date());

    return isInsideHours(hour) && isWorkingDay(weekday); // && !isHoliday(day, month);
  };

  return {
    openChat,
    closeChat,
    openTalkdesk,
    closeTalkdesk,
    hideClassicWidget,
    showClassicWidget,
    openSupportChat,
    closeSupportChat,
    isWithinBusinessHours,
  };
};
